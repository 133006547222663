import cn from 'classnames';
import debounce from 'lodash/debounce';
import { useRouter } from 'next/router';
import { Fragment, InputHTMLAttributes, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import LoadingRing from 'src/components/views/Loaders/LoadingRing/LoadingRing';
import useSearchHistory from 'src/hooks/useSearchHistory/useSearchHistory';
import { useUserAgent } from 'src/modules/user-agent';
import selectDeliveryType from 'src/redux/cart/general/selectDeliveryType';
import { selectCategories } from 'src/redux/categories/categoriesSelectors';
import { useAppSelector } from 'src/redux/hooks';
import useLocalization from 'src/services/localization/useLocalization';
import { useAppConfig } from 'src/utils/appConfig/useAppConfig';
import { selectStoreId } from '../../../redux/cart/general/cartSelectors';
import setSearchBoxIsActive from '../../../redux/search/setSearchBoxIsActive';
import { phone, tabletWidth, tablet_desktop } from '../../../styles/media';
import useSearch from '../../../swr/useSearch';
import { sendClickHistoryItem, sendClickPopularItem, sendOpenSearchPage, sendStartSearch } from '../../../utils/marketing/enhancedEcommerce';
import getPlace from '../../../utils/marketing/getPlace';
import SearchHistory from '../SearchHistory/SearchHistory';
import SearchPopularProducts from '../SearchPopularProducts/SearchPopularProducts';
import SearchResults from '../SearchResults/SearchResults';
import { styles } from './SearchBox.styles';
import useSearchPopularTags from './useSearchPopularTags';

interface Query {
  q?: string;
  'category-id': string;
}

interface SearchBoxProps {
  overlayTopPosition: number;
}

const SearchBox = (props: SearchBoxProps) => {
  const { overlayTopPosition } = props;
  const localize = useLocalization();
  const dispatch = useDispatch();
  const router = useRouter<Query>();
  const inputRef = useRef<HTMLInputElement>();
  const searchResultsRef = useRef<HTMLDivElement>();
  const { chain, settings: { MIN_SEARCH_LENGTH } } = useAppConfig();
  const { os } =  useUserAgent();

  const language = useAppSelector(s => s.app.language);
  const storeId = useAppSelector(selectStoreId);
  const deliveryType = useAppSelector(selectDeliveryType);
  const searchBoxIsActive = useAppSelector(s => s.search.searchBoxIsActive);
  const allCategories = useAppSelector(selectCategories);
  const productModalIsOpen = useAppSelector(s => s.modals.productCardModal.isOpen);

  const initialInputValue = router.query.q
    ? router.query.q
    : '';

  const [inputValue, setInputValue] = useState(initialInputValue);
  const [searchString, setSearchString] = useState('');
  const [startSearchWasSended, setStartSearchWasSended] = useState(false);
  const [resultsHeight, setResultsHeight] = useState(225);

  const setSearchStringDebounce = useMemo(() => debounce(setSearchString, 400), []);

  const {
    fetching,
    products,
    count,
    availableCount,
    categories,
    notFound,
  } = useSearch({ searchValue: searchString, storeId, deliveryType });

  const resultsIsVisible = searchBoxIsActive && inputValue.length >= MIN_SEARCH_LENGTH;

  const {
    tags,
    isLoadingTags,
    popularIsVisible,
  } = useSearchPopularTags({
    searchBoxIsActive,
    resultsIsVisible,
  });

  const {
    history,
    clearHistory,
    removeFromHistory,
    addToHistory,
  } = useSearchHistory();

  const isPhone = useMediaQuery({ query: phone });

  const handleInputFocus = () => {
    setSearchStringDebounce(inputValue);

    if (!isPhone) {
      dispatch(setSearchBoxIsActive(true));
    }
  };

  const disableSearchBox = () => {
    dispatch(setSearchBoxIsActive(false));

    if(!router.query.q) {
      setInputValue(initialInputValue);
    }
  };

  const handleInputChange = event => {
    const searchString = event.target.value;
    setInputValue(searchString);
    setSearchStringDebounce(searchString);
  };

  const handleClearSearch = () => {
    inputRef.current.focus();
    setInputValue('');
    setSearchStringDebounce('');
  };

  const handleViewAll = () => {
    openSearchPage(inputValue);
    handleAddToHistory();
  };

  const openSearchPage = (value: string) => {
    dispatch(setSearchBoxIsActive(false));
    sendOpenSearchPage(value, count, getPlace(router, allCategories));
    const encodedInputValue = encodeURIComponent(value);
    const href = `/[lang]/search/?q=${encodedInputValue}`;
    const as = `/${language}/search/?q=${encodedInputValue}`;
    router.push(href, as);
    window.scrollTo(0, 0);
  };

  const handleKeyDown = event => {
    if (event.key === 'Escape' && !productModalIsOpen && inputValue) {
      handleClearSearch();
    }

    if (event.key === 'Escape' && !productModalIsOpen && !inputValue) {
      inputRef.current.blur();
      disableSearchBox();
    }

    if (event.key === 'Enter') {
      handlePressEnter();
      event.target.blur();
    }
  };

  const handlePressEnter = () => {
    if (inputValue && inputValue.length >= MIN_SEARCH_LENGTH) {
      openSearchPage(inputValue);
      handleAddToHistory();
    }
  };

  useEffect(() => {
    if (window.innerWidth < tabletWidth) {
      setResultsHeight(140);
    }
  }, []);

  useEffect(() => {
    if (!productModalIsOpen && searchBoxIsActive) {
      inputRef.current.focus();
    }
  }, [productModalIsOpen, searchBoxIsActive]);

  useEffect(() => {
    if (window.innerWidth >= tabletWidth && (products.length || notFound)) {
      const results = searchResultsRef.current;
      const resultsHeight = results ? results.clientHeight : 225;
      setResultsHeight(resultsHeight);
    }
  }, [products, notFound]);

  useEffect(() => {
    if (
      !startSearchWasSended
      && searchString.length >= MIN_SEARCH_LENGTH
      && !fetching
      && initialInputValue !== searchString
    ) {
      sendStartSearch(searchString, count, getPlace(router, allCategories));
      setStartSearchWasSended(true);
    }
  }, [
    searchString,
    fetching,
    count,
    startSearchWasSended,
    router,
    allCategories,
    initialInputValue,
    MIN_SEARCH_LENGTH,
  ]);

  useEffect(() => {
    if (searchString === '' && setStartSearchWasSended) {
      setStartSearchWasSended(false);
    }
  }, [searchString, setStartSearchWasSended]);

  const specificAttributes: InputHTMLAttributes<HTMLInputElement> = {};
  if (os === 'ios') specificAttributes.inputMode = 'search';

  const historyIsVisible = searchBoxIsActive && !resultsIsVisible && history.length > 0;
  const spinnerIsVisible = fetching || isLoadingTags;
  const historyOrPopularIsVisible = historyIsVisible || popularIsVisible;

  const handleAddToHistory = () => {
    addToHistory(inputValue);
  };

  const onSearchHistoryItemClick = (item: string) => {
    dispatch(setSearchBoxIsActive(false));
    setInputValue(item);
    sendClickHistoryItem(item);
    window.scroll(0, 0);
  };

  const handlePopularItemClick = (item: string) => {
    setInputValue(item);
    sendClickPopularItem(item);
    openSearchPage(item);
  };

  return (
    <div
      className={cn('SearchBox', {
        'SearchBox_hidden': !searchBoxIsActive,
      })}
      data-testid='searchBox'
      data-marker='Search Box'
      onMouseDown={e => e.stopPropagation()}
      onKeyDown={handleKeyDown}
    >
      {searchBoxIsActive &&
        <Fragment>
          <div
            className='SearchBox__overlay'
            onClick={disableSearchBox}
            data-testid='overlay'
            data-marker='Overlay'
          />
          <span
            className='SearchBox__back icon-arrow-back'
            data-marker='Search Back'
            onClick={disableSearchBox}
          />
        </Fragment>
      }

      <input
        ref={inputRef}
        value={inputValue}
        onFocus={handleInputFocus}
        className='SearchBox__input'
        placeholder={localize(`search.in.${chain}`)}
        type='search'
        onChange={handleInputChange}
        maxLength={100}
        data-marker='Search Input'
        data-testid='searchBoxInput'
        {...specificAttributes}
      />

      <div className='SearchBox__icons'>
        {inputValue &&
          <span
            className={cn('SearchBox__icon SearchBox__clearSearch',
              'icon-close',
              {
                'SearchBox__clearSearch_hidden': !searchBoxIsActive,
              })}
            data-testid='clear-search-button'
            data-marker='Clear search'
            onClick={handleClearSearch}
          />
        }

        <div
          onClick={handlePressEnter}
          className={cn('SearchBox__icon SearchBox__loupeIcon', {
            'SearchBox__loupeIcon_active': inputValue.length >= MIN_SEARCH_LENGTH,
            'SearchBox__loupeIcon_hidden': inputValue && !searchBoxIsActive,
          })}
          data-testid='loupe'
          data-marker='Loupe'
          role='button'
          tabIndex={0}
        >
          <span className='icon-loupe'/>
        </div>
      </div>

      {resultsIsVisible &&
        <div
          className='SearchBox__resultsContainer'
          ref={searchResultsRef}
          data-marker='Search Results'
          onClick={() => window.innerWidth >= tabletWidth && inputRef.current.focus()}
          onTouchStart={() => inputRef.current.blur()}
        >
          {spinnerIsVisible &&
            <div className='SearchBox__spinner' style={{ height: resultsHeight }}>
              <LoadingRing size='37' color={'var(--uiColors-spinner)'} />
            </div>
          }

          {notFound &&
            <div
              className='SearchBox__notFound'
              data-marker='No Results'
            >
              <img
                className='SearchBox__notFoundImage'
                src={`/i/donut.png`}
                alt='Donut'
              />
              <span className='SearchBox__notFoundMessage'>
                {localize('search.result.nothing-found')}
                <strong>{` «${searchString}»`}</strong>
              </span>
            </div>
          }

          {(!!products.length || !!categories.length) &&
            <div id='SearchBox__results' className='SearchBox__results'>
              <SearchResults
                products={products}
                count={count}
                availableCount={availableCount}
                categories={categories}
                allCategories={allCategories}
                language={language}
                searchString={searchString}
                onViewAll={handleViewAll}
                addToHistory={handleAddToHistory}
                disableSearchBox={disableSearchBox}
              />
            </div>
          }
        </div>
      }

      {historyOrPopularIsVisible && (
        <div className='SearchBox__resultsContainer' data-marker="Search Result">
          {historyIsVisible && (
            <SearchHistory
              history={history}
              onRemoveFromHistory={removeFromHistory}
              onClearHistory={clearHistory}
              onItemClick={onSearchHistoryItemClick}
            />
          )}

          {popularIsVisible && (
            <SearchPopularProducts
              items={tags}
              onItemClick={handlePopularItemClick}
            />
          )}
        </div>
      )}

      <style jsx>{styles}</style>
      <style jsx>{`
        @media ${tablet_desktop} {
          .SearchBox__overlay {
            top: ${overlayTopPosition}px;
          }
        }
      `}</style>
    </div>
  );
};

export default SearchBox;
