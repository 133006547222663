import { useDispatch } from 'react-redux';
import Responsive from 'src/components/views/Responsive/Responsive';
import { selectStoreId } from 'src/redux/cart/general/cartSelectors';
import selectSelectedDelivery from 'src/redux/cart/general/selectSelectedDelivery';
import { useAppSelector } from 'src/redux/hooks';
import useLocalization from 'src/services/localization/useLocalization';
import { openModal } from '../../../../../redux/modals';
import { getDeliveryDataFromAddress } from '../../../../../utils/delivery/getDeliveryDataFromAddress';
import getDeliveryText from '../../../../../utils/delivery/getDeliveryText';
import { styles } from './AddressButton.styles';

const AddressButton = () => {
  const localize = useLocalization();
  const dispatch = useDispatch();

  const delivery = useAppSelector(selectSelectedDelivery);
  const cartType = useAppSelector(s => s.app.cartType);
  const storeId = useAppSelector(selectStoreId);

  if (!cartType) {
    return null;
  }

  const deliveryData = getDeliveryDataFromAddress(delivery);
  const deliveryText = getDeliveryText(deliveryData, localize) || localize('address.select-new-2');


  const handleClick = () => {
    dispatch(openModal('addressModal'));
  };

  return (
    <div
      className='AddressButton'
      onClick={handleClick}
      role='button'
      data-marker='Address'
      data-marker-store-id={storeId}
      tabIndex={0}
    >
      <span className='AddressButton__icon icon-pin' />
      <Responsive tabletDesktop>
        <span className='AddressButton__label'>{deliveryText}</span>
      </Responsive>
      <style jsx>{styles}</style>
    </div>
  );
};

export default AddressButton;
