import { Fragment } from 'react';
import ZakazLogo from 'src/components/containers/Header/components/ZakazLogo/ZakazLogo';
import Logo from 'src/components/views/Logo/Logo';
import Responsive from 'src/components/views/Responsive/Responsive';
import ZsuHelp from 'src/components/views/ZsuHelp/ZsuHelp';
import ChooseChains from 'src/modules/ChooseChainsSidebar';
import useToggles from 'src/services/toggles/useToggles';
import DropdownChooseLanguage from '../../../DropdownChooseLanguage/DropdownChooseLanguage';
import AccountNavigationDrop from '../AccountNavigationDrop/AccountNavigationDrop';
import AddressButton from '../AddressButton/AddressButton';
import HeaderScheduleButton from '../HeaderScheduleButton/HeaderScheduleButton';
import { styles } from './HeaderTopLine.styles';

const HeaderTopLine = () => {
  const { CHOOSING_CHAINS_AVAILABLE } = useToggles();

  return (
    <div className='HeaderTopLine' data-marker='Header Logo'>
      <div className='HeaderTopLine__container'>
        <div className='HeaderTopLine__left'>
          {
            CHOOSING_CHAINS_AVAILABLE &&
            <Fragment>
              <ChooseChains />
              <div className="HeaderTopLine__separator" />
            </Fragment>
          }
          <ZakazLogo />
          <div className="HeaderTopLine__logoSeparator" />
          <Logo />
          <div className="HeaderTopLine__separator" />
            <Responsive desktop>
              <div className='HeaderTopLine__actionBox'>
                <ZsuHelp />
                <div className="HeaderTopLine__separator" />
                <DropdownChooseLanguage />
              </div>
          </Responsive>
        </div>
        <div className='HeaderTopLine__right'>
          <AddressButton/>
          <HeaderScheduleButton />
          <AccountNavigationDrop />
        </div>
      </div>

      <style jsx>{styles}</style>
    </div>
  );
};

export default HeaderTopLine;
