import { ProductsCarouselProps } from "src/components/views/ProductsCarousel/ProductsCarousel";
import { Product } from "src/redux/apiTypes";
import { selectCartItems } from "src/redux/cart/general/cartSelectors";
import { useAppSelector } from "src/redux/hooks";
import useFavorites from "src/services/favorites/useFavorites";
import mapViewProducts from "src/utils/product/mapViewProducts";
import useProductsCarouselHandlers from "../useProductsCarouselHandlers/useProductsCarouselHandlers";

interface Params {
  items: Product[];
  place: string;
  title: string;
  handlersPlace: string;
}

const useProductsCarouselProps = (params: Params): ProductsCarouselProps => {
  const { items, place, title, handlersPlace } = params;
  const { getProductIsFavorite } = useFavorites();
  const cartItems = useAppSelector(selectCartItems);
  const language = useAppSelector((s) => s.app.language);
  const itemsCanBeAdded = useAppSelector((s) => !!s.app.cartType);

  const {
    handleAddToCart,
    handleAmountChange,
    handleFavoriteClick,
    handleProductTileClick,
  } = useProductsCarouselHandlers(handlersPlace);

  const sliderItems = mapViewProducts(
    items,
    getProductIsFavorite,
    cartItems,
  );

  return {
    title,
    items: sliderItems,
    productPlace: place,
    language,
    itemsCanBeAdded,
    onProductTileClick: handleProductTileClick,
    onAddToCart: handleAddToCart,
    onAmountChange: handleAmountChange,
    onFavoriteClick: handleFavoriteClick,
  };
};

export default useProductsCarouselProps;
