import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Product } from "src/redux/apiTypes";
import checkExcisableAvailability from "src/redux/catalogue/catalogueOperations/checkExcisableAvailability";
import useToggles from "src/services/toggles/useToggles";

interface Params {
  dataAreReady: boolean;
  categoryId: string;
  categoryProducts: Product[];
}

const useCheckExcisableAvailability = (params: Params) => {
  const {
    dataAreReady,
    categoryId,
    categoryProducts,
  } = params;

  const dispatch = useDispatch();
  const { AGE_CONFIRMATION_IS_ENABLED } = useToggles();

  useEffect(() => {
    if (dataAreReady) {
      dispatch(checkExcisableAvailability({
        selectedCategoryId: categoryId,
        AGE_CONFIRMATION_IS_ENABLED,
      }));
    }
  }, [
    dataAreReady,
    categoryProducts,
    categoryId,
    AGE_CONFIRMATION_IS_ENABLED,
    dispatch,
  ]);
};

export default useCheckExcisableAvailability;
