import cn from 'classnames';
import dynamic from 'next/dynamic';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import LoadingRing from 'src/components/views/Loaders/LoadingRing/LoadingRing';
import Responsive from 'src/components/views/Responsive/Responsive';
import { useAppSelector } from 'src/redux/hooks';
import { openModal } from 'src/redux/modals';
import { AuthTab } from 'src/redux/modals/types';
import useLocalization from 'src/services/localization/useLocalization';
import { styles } from './AccountNavigationDrop.styles';

const AccountNavigation = dynamic(() => import('../../../AccountNavigation/AccountNavigation'), { ssr: false });

const AccountNavigationDrop = () => {
  const [withDropMenu, setWithDropMenu] = useState(false);
  const parent = useRef(null);
  const localize = useLocalization();
  const isLoggedIn = useAppSelector(state => state.user.isLoggedIn);
  const isFetchingCheckLogin = useAppSelector(state => state.user.isFetching.checkLogin);
  const isLoginChecked = useAppSelector(state => state.user.isLoginChecked);
  const dispatch = useDispatch();

  const handleClick = () => {
    setWithDropMenu(!withDropMenu);
  };

  const handleClickAction = (currentTab: AuthTab) => () => {
    dispatch(openModal('loginOrRegistrationModal', { selectedTab: currentTab }));
  };

  useEffect(() => {
    const handleMenuOutClick = (event: MouseEvent) => {
      const parentCurrent = parent.current;

      if (withDropMenu && parentCurrent && !parentCurrent.contains(event.target)) {
        setWithDropMenu(false);
      }
    };

    window.addEventListener('click', handleMenuOutClick);
    return () => { window.removeEventListener('click', handleMenuOutClick); };

  }, [withDropMenu]);


  return (
    <div
      className='AccountNavigationDrop'
      ref={parent}
    >
      {
        isLoggedIn
          ? (
            <button
              type='button'
              className='AccountNavigationDrop__button'
              data-marker='Account'
              data-testid='Account Button'
              onClick={handleClick}
            >
              <span className={cn(
                'AccountNavigationDrop__icon icon-user',
                { AccountNavigationDrop__icon_active: withDropMenu })
              } />
              <Responsive desktop>
                <span className='AccountNavigationDrop__account'>{localize('general.account')}</span>
                <span
                  className={cn(
                    'AccountNavigationDrop__iconCaret', {
                    'icon-caret-bottom': !withDropMenu,
                    'AccountNavigationDrop__iconCaret_open icon-caret-top': withDropMenu,
                  })}
                />
              </Responsive>
            </button>
          )
          : (
            <button
              className='AccountNavigationDrop__button AccountNavigationDrop__button_green'
              onClick={handleClickAction('login')}
              type='button'
              data-marker='Sign in'
              data-testid='sign_in_button'
            >
              <span className='AccountNavigationDrop__icon icon-user' />
              <Responsive desktop>
                <span className='AccountNavigationDrop__account'>
                  {isFetchingCheckLogin || !isLoginChecked
                    ? <LoadingRing size='16' color={'var(--colors-zakazGreen)'} />
                    : localize('general.login-2')
                  }
                </span>
              </Responsive>
            </button>
          )
      }
      {
        withDropMenu && (
          <div className='AccountNavigationDrop__dropdown'>
            <AccountNavigation
              place='dropdown'
              onClick={handleClick}
            />
          </div>
        )
      }
      <style jsx>{styles}</style>
    </div>
  );
};

export default AccountNavigationDrop;
