import { AddressDelivery } from "src/redux/addressManagement/types";
import { QueryString, Store } from "src/redux/apiTypes";

export const getRetailsInfoRequestQueryString = (delivery: AddressDelivery, store: Store): QueryString => {
  let queryString: QueryString = ``;
  const deliveryType = delivery?.type;

  //less then one because address may be contains only delivery type
  if (!delivery || !delivery?.address?.[deliveryType]) {
    return queryString;
  }

  if (deliveryType === 'pickup') {
    const deliveryService = delivery.address.pickup.store.delivery_service;

    queryString = `?include_delivery=1&delivery_service_id=${deliveryService}`;
  }

  if (deliveryType === 'plan') {
    const { coords, delivery_service_id } = delivery.address.plan;

    queryString = `?include_delivery=1&coords=${coords.lat},${coords.lng}&delivery_service_id=${delivery_service_id}`;
  }

  if (deliveryType === 'nova_poshta') {
    const { id } = delivery.address.nova_poshta.settlement;
    const { coords } = store.address;

    queryString = `?include_delivery=1&coords=${coords.lat},${coords.lng}&settlement_id=${id}`;
  }

  if (deliveryType === 'nova_poshta_address') {
    const { id } = delivery.address.nova_poshta_address.address.settlement;
    const { coords } = store.address;

    queryString = `?include_delivery=1&coords=${coords.lat},${coords.lng}&settlement_id=${id}`;
  }

  if (deliveryType === 'nova_poshta_fresh') {
    const { id } = delivery.address.nova_poshta_fresh.settlement;
    const { coords } = store.address;

    queryString = `?include_delivery=1&coords=${coords.lat},${coords.lng}&settlement_id=${id}`;
  }

  return queryString;
};
