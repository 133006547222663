import cn from 'classnames';
import Link from 'next/link';
import * as React from 'react';
import { Fragment, MouseEventHandler, useState } from 'react';
import useLocalization from 'src/services/localization/useLocalization';
import useToggles from 'src/services/toggles/useToggles';
import { getProductTileImage } from 'src/utils/product/getProductTileImage';
import { useProductBadges } from '../../../hooks/product/useProductBadges';
import { Product } from '../../../redux/apiTypes';
import getAmountSuffix from '../../../utils/product/getAmountSuffix';
import getFormattedWeight from '../../../utils/product/getFormattedWeight';
import getProductURL from '../../../utils/product/getProductURL';
import AddButton from '../AddButton/AddButton';
import Badges from '../Badges/Badges';
import Impressionable from '../Impressionable/Impressionable';
import Price from '../Price/Price';
import QuantityBox from '../QuantityBox/QuantityBox';
import { styles } from './ProductTileMini.styles';

export interface ProductTileMiniProps {
  data: Product;
  amount: number;
  withBorderInSlider: boolean;
  productPlace: string;
  language: string;
  itemIsAdded: boolean;
  itemCanBeAdded: boolean;
  addButtonIsBordered?: boolean;
  onClick: MouseEventHandler<HTMLAnchorElement>;
  onAddToCart(): void;
  onAmountChange(amount: number): void;
}

const ProductTileMini: React.FC<ProductTileMiniProps> = props => {
  const {
    data,
    amount,
    withBorderInSlider,
    productPlace,
    language,
    itemIsAdded,
    itemCanBeAdded,
    onClick,
    onAddToCart,
    onAmountChange,
    addButtonIsBordered,
  } = props;

  const { unit, quantity, bundle } = data;

  const [
    renderIsInitialWithAutofocus, setRenderIsInitialWithAutofocus,
  ] = useState(false);

  const localize = useLocalization();

  const { BUNDLES_AVAILABLE } = useToggles();

  const [isVisible, setIsVisible] = useState(false);

  const handleVisibility = (isVisible: boolean) => {
    setIsVisible(isVisible);
  };

  const handleAddToCart = () => {
    setRenderIsInitialWithAutofocus(true);

    onAddToCart();
  };

  const productUrl = getProductURL(language, data.ean, data.slug);

  const { ownBrandLogo } = useProductBadges(data);

  return (
    <Fragment>
      <Impressionable onChange={handleVisibility}>
        <div
          className={cn('ProductTileMini', { 'ProductTileMini_withBorderInSlider': withBorderInSlider })}
        >
          <Link
            href='/[lang]/products/[token]'
            as={productUrl}
            title={data.title}
            onClick={onClick}
            data-testid='product_tile_mini'
            data-marker='Product Tile Mini'
            data-productkey={data.ean} // E-sputnik data-attribute
            data-status={isVisible ? 'visible' : 'invisible'}
            className='ProductTileMini__link'
          >
            {
              ownBrandLogo &&
              <div className="ProductTileMini__badges">
                <Badges ownBrandLogo={ownBrandLogo} size="small" />
              </div>
            }
            <div className="ProductTileMini__productInfo">
              {data.img && (
                <div
                  className='ProductTileMini__imageWrapper'
                >
                  <img
                    className={cn('ProductTileMini__image', {
                      unavailable: !data.in_stock,
                    })}
                    src={getProductTileImage(data.img)}
                    alt={data.title}
                  />
                </div>
              )}

              <div className='ProductTileMini__details'>
                <div className="ProductTileMini__price" data-marker='Price'>
                  {data.price && (
                    <Price
                      price={data.price as number}
                      currency={data.currency}
                      variant="smallAccentedCaption"
                      isDiscount={data.discount.status}
                      isUnavailable={!data.in_stock}
                    />
                  )}
                </div>

                {data.title && (
                  <div className='ProductTileMini__title'>
                    {data.title}
                  </div>
                )}

                {data.unit && (
                  <div
                    className='ProductTileMini__weight'
                    data-marker='Weight'
                  >
                    {getFormattedWeight(data.unit, data.weight, data.volume, localize)}
                  </div>
                )}
              </div>
            </div>
            {data.in_stock && (
              <div
                className={cn('ProductTileMini__footer', {
                  active: itemIsAdded,
                })}
              >
                <div className='ProductTileMini__add'>

                  {
                    itemIsAdded ? (
                      <QuantityBox
                        amount={amount}
                        unit={unit}
                        quantity={quantity}
                        boxSmall
                        suffix={getAmountSuffix(unit, bundle, localize, BUNDLES_AVAILABLE)}
                        low
                        inputMargin={'0 5px'}
                        place={productPlace}
                        initialRenderWithAutofocus={renderIsInitialWithAutofocus}
                        onChangeAmount={onAmountChange}
                      />
                    ) : (
                      <AddButton
                        withSmallHeight={true}
                        onClick={handleAddToCart}
                        disabled={!itemCanBeAdded}
                        isBordered={addButtonIsBordered}
                      />
                    )
                  }
                </div>
              </div>
            )}
          </Link>
        </div>
      </Impressionable>

      <style jsx>{styles}</style>
    </Fragment >
  );
};

export default ProductTileMini;
