import { useRouter } from 'next/router';
import { Fragment, ReactNode } from 'react';
import useToggles from 'src/services/toggles/useToggles';
import { IS_HORECA } from '../../../redux/constants';
import { useAppSelector } from '../../../redux/hooks';
import Loader from '../../views/Loader/Loader';

export interface Props {
  conditionalMode?: boolean;
  children: ReactNode;
}

const WithHorecaAuth = (props: Props) => {
  const {conditionalMode, children} = props;
  const isFetchingProfile = useAppSelector(s => s.userProfile.isFetching);
  const isUserHoreca = useAppSelector(s => s.userProfile.userData[IS_HORECA]);
  const language = useAppSelector(s => s.app.language);
  const router = useRouter();

  const { HORECA_IS_AVAILABLE } = useToggles();

  // show nothing if not horeca
  if (conditionalMode) {
    if (isUserHoreca && HORECA_IS_AVAILABLE) { // isUserHoreca is undefined when no request has been done
      return <Fragment>{children}</Fragment>;
    }
    return null;
  }
  // show loader when isFetchingProfile or no profile request has been sent yet
  if (isFetchingProfile || isUserHoreca === undefined) {
    return <Loader />;
  }
  // redirect if not horeca
  if (!isUserHoreca) {
    const href = `/[lang]/`;
    const as = `/${language}/`;
    router.push(href, as);
    return null;
  }
  return <Fragment>{children}</Fragment>;
};

export default WithHorecaAuth;
