import { Fragment, useRef, useState } from 'react';
import Button from 'src/components/ui-kit/Button/Button';
import useLocalization from 'src/services/localization/useLocalization';
import { Filter } from '../../../redux/apiTypes';
import { Facet } from '../../containers/CatalogActiveFiltersList/getActiveFilters';
import { GetHandlerDeleteFiltersFunc, HandleFilterFunc } from '../../containers/CatalogFilters/CatalogFilters';
import CatalogFiltersMobileCategories from '../CatalogFiltersMobileCategories/CatalogFiltersMobileCategories';
import CatalogFiltersMobileOptions from '../CatalogFiltersMobileOptions/CatalogFiltersMobileOptions';
import { styles } from './CatalogFiltersMobile.styles';
import ContentSorterMobile from './ContentSorterMobile/ContentSorterMobile';

export interface CatalogFiltersMobileProps {
  excludeParams: string[];
  filters: Filter[];
  activeFilters: Facet[];
  isFetchingProducts: boolean;
  handleFilter: HandleFilterFunc;
  deleteFilters: GetHandlerDeleteFiltersFunc;
}

export interface DeleteFiltersFunc {
  (type?: string): () => void
}

const CatalogFiltersMobile = (props: CatalogFiltersMobileProps) => {
  const {
    handleFilter,
    filters = [],
    activeFilters,
    isFetchingProducts,
    excludeParams,
    deleteFilters,
  } = props;

  const localize = useLocalization();
  const catalogFiltersRef = useRef<HTMLDivElement>(null);
  const [isFilterMenuOpened, handleFilterMenuOpened] = useState(false);
  const [selectedFilterType, handlesSelectedFilterType] = useState<Filter | null>(null);

  const handleFiltersOpen = () => {
    const app = document.getElementById('__next') as HTMLElement;
    app.style.overflow = 'hidden';
    app.classList.add('filters-open');
    handleFilterMenuOpened(true);
  };

  const handleFiltersClose = () => {
    const app = document.getElementById('__next') as HTMLElement;
    app.style.overflow = '';
    app.classList.remove('filters-open');
    handleFilterMenuOpened(false);
    handlesSelectedFilterType(null);
    window.scrollTo(0,0);
  };

  const closeCategory = () => {
    handlesSelectedFilterType(null);
  };

  const activeFiltersExists = activeFilters.length > 0;

  return (
    <Fragment>
    <div
      className='CatalogFiltersMobile__panel CatalogFiltersMobile__panel_sticky'
      ref={catalogFiltersRef}
      data-marker='Sticky mobile filters and sort'
      data-status='visible'
    >
      <div className='CatalogFiltersMobile__panelItem'>
        <div
          className='CatalogFiltersMobile__toggle'
          data-testid='filtersToggle'
          data-marker='Mobile filters'
          onClick={handleFiltersOpen}
        >
          <div className='CatalogFiltersMobile__toggleIcon icon-filters' />
          <span className='CatalogFiltersMobile__toggleLabel' data-testid='generalFilters'>
            {localize('general.filters')} {activeFiltersExists ? `(${activeFilters.length})`: ''}
          </span>
          <span className='CatalogFiltersMobile__toggleArrow icon-caret-bottom' />
        </div>
      </div>
      <ContentSorterMobile excludeParams={excludeParams}/>
    </div>
    {isFilterMenuOpened && filters.length > 0 && (
      <Fragment>
        <div
          className='CatalogFiltersMobile__wrap'
          data-marker-popup='Mobile filters'
        >
          {selectedFilterType ? (
            <CatalogFiltersMobileOptions
              activeFilters={activeFilters}
              selectedFilterType={selectedFilterType}
              closeCategory={closeCategory}
              handleFilter={handleFilter}
              deleteFilters={deleteFilters}
              isFetchingProducts={isFetchingProducts}
              excludeParams={excludeParams}
            />
          ) : (
            <CatalogFiltersMobileCategories
              filters={filters}
              activeFilters={activeFilters}
              openCategory={handlesSelectedFilterType}
              handleFilter={handleFilter}
              deleteFilters={deleteFilters}
            />
          )}
          <div className='CatalogFiltersMobile__footer'>
            {selectedFilterType?.key === 'price'
              ? <Button
                  variant='secondary'
                  onClick={closeCategory}
                  fullWidth
                >
                  {localize('back_to_filter_list')}
                </Button>
              : <Button
                  variant={activeFiltersExists ? 'primary' : 'secondary'}
                  onClick={handleFiltersClose}
                  fullWidth
                >
                  {localize(activeFiltersExists ? 'filters.button.submit' : 'general.close')}
                </Button>
            }
          </div>
        </div>
        <div
          className='CatalogFiltersMobile__overlay'
          onClick={handleFiltersClose}
        />
      </Fragment>
    )}

    <style jsx>{styles}</style>
  </Fragment>
  );
};

export default CatalogFiltersMobile;
