import { useEffect, useState } from "react";
import { Product } from "src/redux/apiTypes";
import { selectStoreId } from "src/redux/cart/general/cartSelectors";
import selectDeliveryType from "src/redux/cart/general/selectDeliveryType";
import { useAppSelector } from "src/redux/hooks";
import { useAppConfig } from "src/utils/appConfig/useAppConfig";
import { requestGetFiltersLikeCategoriesProducts } from "../ajax/requestGetFiltersLikeCategoriesProducts";
import useCategoryLoadMoreHandler from "./useCategoryLoadMoreHandler";

const useCustomCategoryPageLoadMore = (initData: Product[], categoryId: string) => {
  const deliveryType = useAppSelector(selectDeliveryType);
  const { fetchConfig } = useAppConfig();
  const storeId = useAppSelector(selectStoreId);
  const [isFetching, setIsFetching] = useState(false);
  const [loadMoreProducts, setLoadMoreProducts] = useState<Product[]>([]);

  useEffect(() => {
    /**
     * reset load more products
     * to display the actual products from getServerSideProps
     * after changing the query parameters
     */
    setLoadMoreProducts([]);
  }, [initData]);

  const onLoadMore = async (queryString: string) => {
    setIsFetching(true);
    const response = await requestGetFiltersLikeCategoriesProducts({
      queryString,
      deliveryType,
      storeId,
      categoryId,
      fetchConfig,
    });

    setLoadMoreProducts([...loadMoreProducts, ...response.results]);
    setIsFetching(false);
  };

  const { handleLoadMore } = useCategoryLoadMoreHandler({ onLoadMore });

  return {
    handleLoadMore,
    productsToShow: [...initData, ...loadMoreProducts],
    isFetching,
  };
};

export default useCustomCategoryPageLoadMore;
