import { useRouter } from 'next/router';
import FilterPriceWrap from 'src/components/views/FilterPriceWrap/FilterPriceWrap';
import useLocalization from 'src/services/localization/useLocalization';
import { Filter } from '../../../redux/apiTypes';
import toggleFilter from '../../../utils/filter/toggleFilter';
import CheckList from '../../views/CheckList/CheckList';
import CollapsePanel from '../../views/CollapsePanel/CollapsePanel';
import Responsive from '../../views/Responsive/Responsive';
import { styles } from './CatalogFilters.styles';

export interface CatalogFiltersProps {
  filters: Filter[];
  isFetchingProducts: boolean;
  excludeParams: string[];
  filtersAsLinks?: boolean;
}

export interface HandleFilterFunc {
  (type: string, name: string): () => void
}

export interface GetHandlerDeleteFiltersFunc {
  (type: string): () => void
}

const CatalogFilters = (props: CatalogFiltersProps) => {
  const localize = useLocalization();
  const router = useRouter();

  const {
    filters,
    isFetchingProducts,
    excludeParams,
    filtersAsLinks,
  } = props;

  const handleFilter: HandleFilterFunc = (type, name) => () => {
    toggleFilter(type, name, router, excludeParams);
  };

  if (!filters?.length) {
    return null;
  }


  return (
    <div className='CatalogFilters'>
      <Responsive desktop>
        <div className='CatalogFilters__wrap' data-marker='Filters'>
          <div className='CatalogFilters__section'>
            <div className='CatalogFilters__sectionTitle'>
              <span>{localize('general.filters')}</span>
            </div>
            {filters.map((filter) => {
              if (filter.type === 'single') {
                return (
                  <CheckList
                    key={filter.key}
                    filter={filter}
                    handleFilter={handleFilter}
                    isFetchingProducts={isFetchingProducts}
                    itemsAsLinks={filtersAsLinks}
                  />
                );
              }

              if (filter.type === 'range') {
                return (
                  <div key={filter.key} className='CatalogFilters__widget'>
                    <FilterPriceWrap
                      filter={filter}
                      excludeParams={excludeParams}
                    />
                  </div>
                );
              }

              return (
                <div key={filter.key} className='CatalogFilters__widget'>
                  <CollapsePanel
                    label={filter.name}
                    isCollapse={filter.is_standart}
                  >
                    <CheckList
                      filter={filter}
                      handleFilter={handleFilter}
                      hasSearch
                      isFetchingProducts={isFetchingProducts}
                      itemsAsLinks={filtersAsLinks}
                    />
                  </CollapsePanel>
                </div>
              );
            })}
          </div>
        </div>
      </Responsive>

      <style jsx>{styles}</style>
    </div>
  );
};

export default CatalogFilters;
