import { MainSiteDelivery } from "src/modules/AddressManagement/components/DeliveryUrlWatcher/types";
import { AddressDelivery } from "src/redux/addressManagement/types";

export const convertAddressToMainSiteDelivery = (delivery: AddressDelivery): MainSiteDelivery => {
  const deliveryType = delivery?.type;

  if (!delivery || !delivery?.address?.[deliveryType]) {
    return null;
  }

  if (deliveryType === 'pickup') {
    const deliveryServiceId = delivery.address.pickup.store.delivery_service;

    return {
      type: deliveryType,
      deliveryServiceId,
    };
  }

  if (deliveryType === 'plan') {
    const { coords, building, city, street, delivery_service_id } = delivery.address.plan;

    return {
      type: deliveryType,
      deliveryServiceId: delivery_service_id,
      geocodedAddress: {
        city,
        building,
        street,
        coords,
      },
    };
  }

  if (deliveryType === 'nova_poshta') {
    const { settlement, warehouse } = delivery.address.nova_poshta;

    return {
      type: deliveryType,
      settlement,
      warehouse,
    };
  }

  if (deliveryType === 'nova_poshta_address') {
    const { settlement, building, street } = delivery.address.nova_poshta_address.address;

    return {
      type: deliveryType,
      settlement,
      building,
      street,
    };
  }

  return null;
};
