import { AddressDelivery } from "src/redux/addressManagement/types";
import { QueryString } from "src/redux/apiTypes";
import { convertAddressToMainSiteDelivery } from "./convertAddressToMainSiteDelivery";

const getRetailInfoQueryStringForLink = (delivery: AddressDelivery): QueryString => {
  //less then one because address may be contains only delivery type
  if (!delivery) {
    return '';
  }

  const mainSiteUserDelivery = convertAddressToMainSiteDelivery(delivery);
  const queryString: QueryString = mainSiteUserDelivery
    ?`?delivery=${encodeURIComponent(JSON.stringify(mainSiteUserDelivery))}`
    : '';

  return queryString;
};

export default getRetailInfoQueryStringForLink;
