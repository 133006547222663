import { Product } from '../../../redux/apiTypes';
import CartProductTile from '../CartProductTile/CartProductTile';
import { styles } from './ProductsBox.styles';

interface Props {
  title?: string;
  items: Product[];
  productPlace?: string;
}

const ProductsBox = (props: Props) => {
  const {
    title = null,
    items = null,
    productPlace,
  } = props;


  return (
    <div className='ProductsBox' data-marker='Products Box'>
      {title && <h2 className='ProductsBox__title'>{title}</h2>}
      {items && (
        <div className='ProductsBox__list'>
          {items.map((item) => {
            return (
              <div key={item.ean} className='ProductsBox__listItem'>
                <CartProductTile
                  data={item}
                  productPlace={productPlace}
                />
              </div>
            );
          })}
        </div>
      )}

      <style jsx>{styles}</style>
    </div>
  );
};

export default ProductsBox;
