import { useDispatch } from "react-redux";
import getClosestSlot from "src/modules/ScheduleDelivery/helpers/getClosestSlot";
import useScheduleProps from "src/modules/ScheduleDelivery/hooks/useScheduleProps";
import selectDeliveryType from "src/redux/cart/general/selectDeliveryType";
import selectSelectedDelivery from "src/redux/cart/general/selectSelectedDelivery";
import { useAppSelector } from "src/redux/hooks";
import { openModal } from "src/redux/modals";
import selectScheduleDeliveryProps from "src/swr/useScheduleDelivery/selectScheduleDeliveryProps";
import useScheduleDelivery from "src/swr/useScheduleDelivery/useScheduleDelivery";
import hasAddressCoords from "src/utils/delivery/hasAddressCoords";
import { sendOpenSchedule } from "src/utils/marketing/enhancedEcommerce";

const useHeaderScheduleButtonProps = () => {
  const delivery = useAppSelector(selectSelectedDelivery);
  const storeIsChosen = hasAddressCoords(delivery);
  const deliveryType = useAppSelector(selectDeliveryType);
  const userIsLoggedIn = useAppSelector(s => s.user.isLoggedIn);
  const { timeWindow } = useScheduleProps();
  const scheduleDeliveryProps = useAppSelector(selectScheduleDeliveryProps);
  const { scheduleDeliveryList } = useScheduleDelivery(scheduleDeliveryProps);
  const scheduleClosestSlot = getClosestSlot(scheduleDeliveryList);
  const language = useAppSelector(s => s.app.language);
  const dispatch = useDispatch();

  const handleSlotsClick = () => {
    const noAvailableSlots = scheduleClosestSlot === null;

    if (noAvailableSlots) {
      dispatch(openModal('noAvailableSlotsModal'));
    } else if (scheduleDeliveryList && scheduleDeliveryList.length) {
      dispatch(openModal('availableDeliverySlotsModal'));
    }

    sendOpenSchedule();
  };

  return {
    handleSlotsClick,
    visibleSlot: userIsLoggedIn ? timeWindow : scheduleClosestSlot,
    deliveryType,
    storeIsChosen,
    language,
    withDeliveryPrefix: !userIsLoggedIn,
  };
};

export default useHeaderScheduleButtonProps;
