import cn from 'classnames';
import { useRouter } from 'next/router';
import { Fragment } from 'react';
import ReactPlaceholder from 'react-placeholder/lib';
import MediaQuery from 'react-responsive';
import ProductsBox from 'src/components/containers/ProductsBox/ProductsBox';
import Button from 'src/components/ui-kit/Button/Button';
import Pagination from 'src/components/views/Pagination/Pagination';
import ProductTilePlaceholder from 'src/components/views/ProductTilePlaceholder/ProductTilePlaceholder';
import ScrollUpButton from 'src/components/views/ScrollUpButton/ScrollUpButton';
import { Product } from 'src/redux/apiTypes';
import useLocalization from 'src/services/localization/useLocalization';
import { tablet_desktop } from 'src/styles/media';
import { useAppConfig } from 'src/utils/appConfig/useAppConfig';
import makeSearchString from 'src/utils/system/makeSearchString';
import { styles } from './CategoryProductBox.styles';

export interface Query {
  page: string;
}

export interface Props {
  title?: string;
  products: Product[];
  excludeParams: string[];
  isFetchingProducts?: boolean;
  productPlace: string;
  totalCount: number;
  onPageChange?(page: string): void;
  onLoadMore(): void;
}

export default function CategoryProductBox(props: Props) {
  const {
    title,
    products,
    excludeParams,
    isFetchingProducts,
    productPlace,
    totalCount,
    onPageChange,
    onLoadMore,
  } = props;

  const { settings: { DEFAULT_PRODUCTS_PER_PAGE } } = useAppConfig();

  const localize = useLocalization();
  const router = useRouter<Query>();

  const { query, push, asPath, pathname } = router;

  const queryPage = Number(query.page || '');
  const currentPage = queryPage > 1 ? queryPage - 1 : 0;

  const updateQueryPage = (page: number) => {
    const newPage = page + 1;
    const newQuery = {
      ...query,
      page: newPage !== 1 ? newPage : undefined,
    };

    const searchString = makeSearchString(newQuery, excludeParams);
    const path = asPath.split('?')[0];

    push({
      pathname,
      query: newQuery,
    }, `${path}${searchString}`);
    window.scrollTo(0, 0);
  };

  const handlePageChange = (data) => {
    const { selected } = data;

    updateQueryPage(selected);

    if (typeof onPageChange === 'function') {
      onPageChange(selected + 1);
    }
  };

  const getPageLink = (pageNumber: number): string => {
    const newQuery = {
      ...query,
      page: pageNumber,
    };
    const path = asPath.split('?')[0];
    const searchString = makeSearchString(newQuery, excludeParams);

    return pageNumber > 1 ? `${path}${searchString}` : path;
  };

  const initialCount = DEFAULT_PRODUCTS_PER_PAGE;
  const pageCount = totalCount ? Math.ceil(totalCount / initialCount) : 1;
  const showPagination = pageCount > 1;
  const showLoadMore = showPagination && pageCount > queryPage;

  return (
    <Fragment>
      {products.length > 0 && (
        <ProductsBox
          title={title}
          items={products}
          productPlace={productPlace}
        />
      )}

      {showLoadMore && (
        <ReactPlaceholder
          ready={!isFetchingProducts}
          customPlaceholder={<ProductTilePlaceholder repeat={5} />}
        >
          <div className='CategoryProductBox__loadMore'>
            <Button
              onClick={onLoadMore}
              dataMarkerValue='Load More'
            >
              {localize('show_more')}
            </Button>
          </div>
        </ReactPlaceholder>
      )}

      {showPagination && (
        <div
          className={cn({
            'CategoryProductBox__paginationWithMargin': !showLoadMore,
          })}
        >
          <Pagination
            paginationOptions={{
              pageCount,
              marginPagesDisplayed: 1,
              pageRangeDisplayed: 5,
              forcePage: currentPage,
              disableInitialCallback: true,
              onPageChange: handlePageChange,
              hrefBuilder: getPageLink,
            }}
          />
        </div>
      )}

      <MediaQuery query={tablet_desktop}>
        <ScrollUpButton behavior='smooth' />
      </MediaQuery>

      <style jsx>{styles}</style>
    </Fragment>
  );
}
